<template>
    <div>
      <page-title :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">List of Expenses</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" @click="onCreateItem" class="">
						<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mt-3">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">&nbsp;&nbsp;entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
                        <a-input-search
                            placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"
                            @search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-error mr-3"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:responsive="true"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="amount" slot-scope="amount">
									{{ parseInt(amount).toLocaleString() }}/=
								</template>

								<template slot="date" slot-scope="date">
									{{ $Moment(date).format("DD MMM YYYY") }}
								</template>

								<template slot="method" slot-scope="method">
									{{ `${method[0].toUpperCase()}${method.substring(1)}` }}
								</template>

								<template slot="status" slot-scope="status">
									<a-badge  
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="`${status[0].toUpperCase()}${status.substring(1)}`" 
										:number-style="status == 'approved' ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : status == 'rejected' ? { backgroundColor: '#F44335', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>

								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<!-- <a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn" style="color: #FB8C00;" @click="onEditItem(record)">
										<a-icon type="form" /> Edit
									</a-button>
									&nbsp;&nbsp; -->
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-primary" style="" :href="`/pharmacy-management/expenses/view/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-2 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-2">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>

		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add Expense' : 'Edit Expense'}}</h4>
			<a-form
				:model="newItem"
                :form="form"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Category"
					placeholder="Select Category"
					:colon="false">
					<a-select placeholder="Select Category" 
						name="categoryUuid"
						v-decorator="[ 'categoryUuid',
						{ rules: [{ required: true, message: 'Please select category of the expense!' }] },]">
						<a-select-option v-for="option in expenseCategories"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Amount"
					:colon="false">
						<a-input 
							name="amount"
							type="number"
							v-decorator="[ 'amount',
								{ rules: [{ required: true, message: 'Please enter the amount to be spent!' }] },
							]"
							placeholder="Amount"/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Payment Method"
					placeholder="Select Payment Method"
					:colon="false">
					<a-select placeholder="Select Payment Method" 
						name="method"
						v-decorator="[ 'method',
						{ rules: [{ required: true, message: 'Please select method of payment!' }] },]">
						<a-select-option v-for="option in methods"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Date"
					:colon="false">
						<a-date-picker
							format="DD MMM YYYY"
							style="width: 100%"
							v-decorator="['date', { rules: [{ required: true, message: 'Please select the entry date!' }] }]"
							placeholder="Date" />
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Description"
					:colon="false">
						<a-textarea 
							name="description"
							v-decorator="['description', { rules: [] }]"
							placeholder="Description"/>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="">
						{{ newItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


		<!-- DELETEE CONFIRMATION -->
		<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this record?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'CATEGORY',
			dataIndex: 'category.name',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			scopedSlots: { customRender: 'amount' },
			// width: "13%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'DATE',
			dataIndex: 'date',
			scopedSlots: { customRender: 'date' },
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'METHOD',
			dataIndex: 'method',
			scopedSlots: { customRender: 'method' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		// {
		// 	title: 'STATUS',
		// 	dataIndex: 'status',
		// 	scopedSlots: { customRender: 'status' },
		// 	// width: "8%"
		// 	// sorter: (a, b) => a.name.length - b.name.length,
		// 	// sortDirections: ['descend', 'ascend'],
		// },
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
			// width: "10%"
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];



    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {

				subheading: 'Sales',

				pagination: {
					search: '',
					loading: false,
					perPage: 10,
					currentPage: 1,
					perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
					total: 10
				},

				columns,
				message: '',

				records: [],
				expenseCategories: [],
				methods: [
					{ uuid: "cash", name: "Cash" },
					{ uuid: "bank", name: "Bank" },
					{ uuid: "nhif", name: "NHIF" },
					{ uuid: "mobile-money", name: "Mobile Money" },
				],
				
				form: '',
				rules,
				newItem: {
					uuid: null,
					companyUuid: null,
					categoryUuid: null,
					method: null,
					amount: null,
					date: null,
					description: null,
					isCreate: true,
					loading: false,
					showModal: false,
				},

				deleteDataItem: {
					uuid: null,
					showModal: false,
				},

				userDetails: {
					user: null,
					company: null
				},

				notify: {
					text: null,
					type: null,
				},
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
		computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getExpenses();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getExpenses();
			this.getExpenseCategory();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.pagination.currentPage = 1;
					this.pagination.perPage = 10;
					
					this.getExpenses()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getExpenses();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getExpenses();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getExpenses();
            },


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },

			async getExpenses() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/expenses/${companyUuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.records = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            this.notify.text = "There is no data available";
							this.notify.type = "success";
                            this.$notify(this.notify);
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
            },


			async getExpenseCategory() {

                const currentPage = 1;
				const perPage = 1000;

				let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/expense-categories/${companyUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.expenseCategories = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)

                });
            },



			onCreateItem() {
                // this.newItem.uuid = null;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				setTimeout(() => {
					this.form.setFieldsValue({
						categoryUuid: "",
						method: "cash",
						amount: "",
						date: "",
						description: "",
					});
				}, 10);
				this.newItem.isCreate = true;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },

			async onEditItem(item) {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.newItem.isCreate = false;
				this.newItem.showModal = true;
                this.newItem.uuid = item.uuid;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.loading = false;
				// eslint-disable-next-line no-console
				setTimeout(() => {
					this.form.setFieldsValue({
						categoryUuid: item.category.uuid,
						method: item.method,
						amount: item.amount,
						date: item.date,
						description: item.description,
					});
				}, 10);

            },


			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						if(this.newItem.isCreate) {

							this.newItem.loading = true;
				
							let url = `${this.$BACKEND_URL}/expenses`
							
							let payload = values;

							payload.companyUuid = this.userDetails.company.uuid;
							payload.date = this.$Moment(values.date._d).format("YYYY/MM/DD")

							this.$AXIOS.post(url, payload).then(response => {

								if (response.status >= 200 && response.status < 210) {

									this.form.resetFields()

									this.newItem.uuid = null;
									this.newItem.categoryUuid = null;
									this.newItem.amount = null;
									this.newItem.date = null;
									this.newItem.method = null;
									this.newItem.description = null;
									this.newItem.isCreate = true;
									this.newItem.loading = false;
									this.newItem.showModal = false;

									this.notify.text = response.data.message
									this.notify.type = "success"

									this.$notify(this.notify)

								}

								this.getExpenses()

							}).catch(error => {
							
								this.newItem.loading = false;
					
								if(error.response != null && error.response.status == 401) {
									this.$router.push(`/`);
								}

								this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
								this.notify.type = "error"

								this.$notify(this.notify)
					
							});

						}else{

							this.newItem.loading = true;

							// eslint-disable-next-line no-console
							console.log(this.form)
				
							let url = `${this.$BACKEND_URL}/expenses/${this.newItem.uuid}`

							let payload = values;
							payload.date = this.$Moment(values.date._d).format("YYYY/MM/DD")

							this.$AXIOS.patch(url, payload).then(response => {

								if (response.status >= 200 && response.status < 210) {

									this.form.resetFields()

									this.newItem.uuid = null;
									this.newItem.categoryUuid = null;
									this.newItem.amount = null;
									this.newItem.date = null;
									this.newItem.method = null;
									this.newItem.description = null;
									this.newItem.isCreate = true;
									this.newItem.loading = false;
									this.newItem.showModal = false;


									this.notify.text = response.data.message
									this.notify.type = "success"

									this.$notify(this.notify)

								}

								this.getExpenses()
				
							}).catch(error => {
							
								this.newItem.loading = false;
					
								if(error.response != null && error.response.status == 401) {
									this.$router.push(`/`);
								}

								this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
								this.notify.type = "error"

								this.$notify(this.notify)
							});
						}

					}
				})
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},

			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/expenses/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.getExpenses();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
			},


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>